function getApiAddress() {
    let host = window.location.host.replace("www.", "")

    if (host.includes("localhost"))
        return "http://localhost:8000"

    return "https://api." + host
}

export default {
    UserAccess: {
        Authorization: {
            Login() {
                return getApiAddress() + "/UserAccess/Authorization/Login"
            },
            LoginWithState(thirdParty, state) {
                return getApiAddress() + "/UserAccess/Authorization/Login/" + thirdParty + "/" + state
            },
            OAuth(thirdParty, redirectUri) {
                return getApiAddress() + "/UserAccess/Authorization/OAuth/" + thirdParty + "/" + encodeURIComponent(redirectUri)
            },
            RefreshToken() {
                return getApiAddress() + "/UserAccess/Authorization/RefreshToken"
            },
        },
        UserRegistrations: {
            Register() {
                return getApiAddress() + "/UserAccess/UserRegistrations"
            },
            RegisterWithThirdParty() {
                return getApiAddress() + "/UserAccess/UserRegistrations/ThirdParty"
            },
            Confirm(registrationId) {
                return getApiAddress() + "/UserAccess/UserRegistrations/" + registrationId + "/Confirm"
            } 
        },
        Users: {
            GetUserById(userId) {
                return getApiAddress() + "/UserAccess/Users/" + userId
            },
            LookupByIds() {
                return getApiAddress() + "/UserAccess/Users/LookupByIds"
            },
            BanUserById(userId) {
                return getApiAddress() + "/UserAccess/Users/" + userId + "/Ban"
            },
            ConnectWithThirdPartyByState(userId, thirdParty, state) {
                return getApiAddress() + "/UserAccess/Users/" + userId + "/Connect/" + thirdParty + "/" + state
            }
        },
        PasswordResets: {
            CreatePasswordReset() {
                return getApiAddress() + "/UserAccess/PasswordReset/"
            },
            ConfirmPasswordReset(passwordResetId) {
                return getApiAddress() + "/UserAccess/PasswordReset/" + passwordResetId + "/Confirm"
            }
        }
    },
    UserContent: {
        UserProfiles: {
            GetUserProfileById(profileId) {
                return getApiAddress() + "/UserContent/UserProfiles/" + profileId
            },
            GetUserProfileByUserId(userId) {
                return getApiAddress() + "/UserContent/UserProfiles/User/" + userId
            },
            GetUserProfileByHandle(handle) {
                return getApiAddress() + "/UserContent/UserProfiles/Handle/" + handle
            },
            GetUserProfileByThirdPartyByHandle(thirdParty, handle) {
                return getApiAddress() + "/UserContent/UserProfiles/" + thirdParty + "/Handle/" + handle
            },
            ChangeUserProfileSettings(profileId) {
                return getApiAddress() + "/UserContent/UserProfiles/" + profileId + "/Settings"
            },
            GetFollowsByProfileId(profileId) {
                return getApiAddress() + "/UserContent/UserProfiles/" + profileId + "/Follows"
            },
            FollowProfile(profileId) {
                return getApiAddress() + "/UserContent/UserProfiles/" + profileId + "/Follow"
            },
            UnfollowProfile(profileId) {
                return getApiAddress() + "/UserContent/UserProfiles/" + profileId + "/Unfollow"
            },
            GetHomeContent(profileId, skip, take) {
                return getApiAddress() + "/UserContent/UserProfiles/" + profileId + "/HomeContent/Skip/" + skip + "/Take/" + take
            },
            GetUserProfileCount() {
                return getApiAddress() + "/UserContent/UserProfiles/Count"
            },
            LookupByUserIds() {
                return getApiAddress() + "/UserContent/UserProfiles/LookupByUserIds"
            },
            GetAllUserProfiles(skip, take) {
                return getApiAddress() + "/UserContent/UserProfiles/Skip/" + skip + "/Take/" + take
            },
            CreateThirdPartyProfile(thirdParty) {
                return getApiAddress() + "/UserContent/UserProfiles/" + thirdParty + "/Create"
            }
        },
        Audio: {
            UploadAudio() {
                return getApiAddress() + "/UserContent/Audio"
            },
            EditAudio(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/Edit"
            },
            GetAudioFileUrl(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/File"
            },
            GetThirdPartyPageAndFileUrl(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/ThirdPartyPageAndFile"
            },
            GetAudioByIdIncludeProfile(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId
            },
            GetAudioByHandleAndSlugIncludeProfile(handle, slug) {
                return getApiAddress() + "/UserContent/Audio/" + handle + "/" + slug
            },
            GetAudioByHandleAndSlugAndThirdPartyIncludeProfile(handle, slug, thirdParty) {
                return getApiAddress() + "/UserContent/Audio/" + thirdParty + "/" + handle + "/" + slug
            },
            GetAudioStatusById(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/Status"
            },
            GetAudiosByProfileIdSkipTake(profileId, skip, take) {
                return getApiAddress() + "/UserContent/Audio/Profile/" + profileId + "/Skip/" + skip + "/Take/" + take
            },
            DeleteAudio(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId
            },
            UpvoteAudio(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/Upvote"
            },
            UnvoteAudio(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/Unvote"
            },
            ListenAudio(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/Listen"
            },
            GetCommentsByAudioId(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/Comments"
            },
            CreateComment(audioId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/Comments"
            },
            DeleteComment(audioId, commentId) {
                return getApiAddress() + "/UserContent/Audio/" + audioId + "/Comments/" + commentId
            },
            ForgetAllSasTokens() {
                return getApiAddress() + "/UserContent/Audio/ForgetAllSasTokens"
            },
            TotalListenCount(profileId) {
                return getApiAddress() + "/UserContent/Audio/TotalListenCount" + (profileId ? "/Profile/" + profileId : "")
            },
            TotalUpvoteCount(profileId) {
                return getApiAddress() + "/UserContent/Audio/TotalUpvoteCount" + (profileId ? "/Profile/" + profileId : "")
            },
            Count(profileId) {
                return getApiAddress() + "/UserContent/Audio/Count" + (profileId ? "/Profile/" + profileId : "")
            },
            LookupByIds() {
                return getApiAddress() + "/UserContent/Audio/LookupByIds"
            }
        },
        SearchContent: {
            SearchSkipTake(query, skip, take) {
                return getApiAddress() + "/UserContent/SearchContent?query=" + query + "&skip=" + skip + "&take=" + take
            },
            ReindexSearch() {
                return getApiAddress() + "/UserContent/SearchContent/ReindexSearch"
            }
        },
        UserHistory: {
            GetUserHistoryById(userId) {
                return getApiAddress() + "/UserContent/UserHistory/" + userId
            }
        },
        Follow: {
            Unfollow(followId) {
                return getApiAddress() + "/UserContent/Follow/" + followId
            },
            IsFollowingProfile(profileId, targetProfileId) {
                return getApiAddress() + "/UserContent/Follow/Is/" + profileId + "/Following/" + targetProfileId
            }
        },
        Tag: {
            GetAll() {
                return getApiAddress() + "/UserContent/Tag"
            },
            LookupByIds() {
                return getApiAddress() + "/UserContent/Tag/LookupByIds"
            }
        },
        Report: {
            CreateReport() {
                return getApiAddress() + "/UserContent/Report"
            },
            GetPendingReportsSkipTake(skip, take) {
                return getApiAddress() + "/UserContent/Report/Pending/Skip/" + skip + "/Take/" + take
            },
            ResolveReport(reportId) {
                return getApiAddress() + "/UserContent/Report/" + reportId + "/Resolve"
            }
        },
        Import: {
            CheckProfileExists(importType, username) {
                return getApiAddress() + "/UserContent/Import/" + importType + "/" + username + "/Exists"
            },
            CreateProfileOwnership(importType, username) {
                return getApiAddress() + "/UserContent/Import/" + importType + "/" + username + "/ProfileOwnership/Create"
            },
            ConfirmProfileOwnership(importType, username) {
                return getApiAddress() + "/UserContent/Import/" + importType + "/" + username + "/ProfileOwnership/Confirm"
            },
            GetImportAudios(importType, username) {
                return getApiAddress() + "/UserContent/Import/" + importType + "/" + username + "/Audio"
            },
            BeginImport(importType, username) {
                return getApiAddress() + "/UserContent/Import/" + importType + "/" + username + "/Begin"
            },
            GetImport() {
                return getApiAddress() + "/UserContent/Import"
            },
            GetMostRecentImportTask() {
                return getApiAddress() + "/UserContent/Import/ImportTask/MostRecent"
            }
        }
    },
    Statistics: {
        Charts: {
            TotalAudioListensByTime(audioId, creatorId, from, to) {
                return getApiAddress() + "/Statistics/Charts/TotalAudioListensByTime" + (audioId ? ("/Audio/" + audioId) : (creatorId ? "/Creator/" + creatorId : "")) + "/From/" + from + "/To/" + to
            },
            TotalAudioListenTimeByTime(audioId, creatorId, from, to) {
                return getApiAddress() + "/Statistics/Charts/TotalAudioListenTimeByTime" + (audioId ? ("/Audio/" + audioId) : (creatorId ? "/Creator/" + creatorId : "")) + "/From/" + from + "/To/" + to
            },
            TotalAudioListensByWeekdaysByTime(audioId, creatorId, from, to) {
                return getApiAddress() + "/Statistics/Charts/TotalAudioListensByWeekdaysByTime" + (audioId ? ("/Audio/" + audioId) : (creatorId ? "/Creator/" + creatorId : "")) + "/From/" + from + "/To/" + to
            },
            TotalAudioListensByHoursByTime(audioId, creatorId, from, to) {
                return getApiAddress() + "/Statistics/Charts/TotalAudioListensByHoursByTime" + (audioId ? ("/Audio/" + audioId) : (creatorId ? "/Creator/" + creatorId : "")) + "/From/" + from + "/To/" + to
            },
            MostListenedCreatorsByFromTo(take, from, to) {
                return getApiAddress() + "/Statistics/Charts/MostListenedCreatorsByTime/Take/" + take + "/From/" + from + "/To/" + to
            },
            MostListenedAudiosByFromTo(creatorId, take, from, to) {
                return getApiAddress() + "/Statistics/Charts/MostListenedAudiosByTime" + (creatorId ? "/Creator/" + creatorId : "") + "/Take/" + take + "/From/" + from + "/To/" + to
            },
            MostListenedCategoriesByFromTo(creatorId, take, from, to) {
                return getApiAddress() + "/Statistics/Charts/MostListenedCategoriesByTime" + (creatorId ? "/Creator/" + creatorId : "") + "/Take/" + take + "/From/" + from + "/To/" + to
            },
            ListenerGendersByTime(audioId, creatorId, from, to) {
                return getApiAddress() + "/Statistics/Charts/ListenerGendersByTime" + (audioId ? ("/Audio/" + audioId) : (creatorId ? "/Creator/" + creatorId : "")) + "/From/" + from + "/To/" + to
            },
            ListenHeatmap(audioId, lengthSeconds) {
                return getApiAddress() + "/Statistics/Charts/ListenHeatmap/Audio/" + audioId + "/Length/" + lengthSeconds
            },
            SearchOccurenceByTime(audioId, creatorId, from, to) {
                return getApiAddress() + "/Statistics/Charts/SearchOccurenceByTime" + (audioId ? "/Audio/" + audioId : "/Creator/" + creatorId) + "/From/" + from + "/To/" + to
            },
        },
        ListenSession: {
            ListenToAudio(audioId) {
                return getApiAddress() + "/Statistics/ListenSession/Listen/" + audioId
            }
        }
    },
    Monetization: {
        AccountApplication: {
            GetAllAccountApplications(skip, take) {
                return getApiAddress() + "/Monetization/AccountApplication/Skip/" + skip + "/Take/" + take
            },
            GetAccountApplicationByUserId(userId) {
                return getApiAddress() + "/Monetization/AccountApplication/" + userId
            },
            CreateAccountApplication(userId) {
                return getApiAddress() + "/Monetization/AccountApplication/" + userId
            },
            ApproveAccountApplication(userId) {
                return getApiAddress() + "/Monetization/AccountApplication/" + userId + "/Approve"
            },
            DenyAccountApplication(userId) {
                return getApiAddress() + "/Monetization/AccountApplication/" + userId + "/Deny"
            }
        },
        Account: {
            GetAllAccounts(skip, take) {
                return getApiAddress() + "/Monetization/Account/Skip/" + skip + "/Take/" + take
            },
            GetAccountByUserId(userId) {
                return getApiAddress() + "/Monetization/Account/" + userId
            },
            EnableAccountByUserId(userId) {
                return getApiAddress() + "/Monetization/Account/" + userId + "/Enable"
            },
            DisableAccountByUserId(userId) {
                return getApiAddress() + "/Monetization/Account/" + userId + "/Disable"
            }
        },
        BillingItem: {
            GetPriceSums() {
                return getApiAddress() + "/Monetization/BillingItem/PriceSums"
            },
            GetWithoutInvoiceByUserId(userId) {
                return getApiAddress() + "/Monetization/BillingItem/User/" + userId + "/WithoutInvoice"
            },
            GetWithoutInvoiceByUserIdsGroupByUserId() {
                return getApiAddress() + "/Monetization/BillingItem/Users/WithoutInvoice"
            }
        },
        InvoiceTemplate: {
            CreateInvoiceTemplate(userId) {
                return getApiAddress() + "/Monetization/InvoiceTemplate/User/" + userId + "/Create"
            },
            EditInvoiceTemplate(id) {
                return getApiAddress() + "/Monetization/InvoiceTemplate/" + id + "/Edit"
            },
            DeleteInvoiceTemplate(id) {
                return getApiAddress() + "/Monetization/InvoiceTemplate/" + id
            },
            GetAllInvoiceTemplatesWithoutInvoice() {
                return getApiAddress() + "/Monetization/InvoiceTemplate/WithoutInvoice"
            },
            GetInvoiceTemplatesById(id) {
                return getApiAddress() + "/Monetization/InvoiceTemplate/" + id
            }
        },
        Invoice: {
            CreateInvoiceFromTemplate(templateId) {
                return getApiAddress() + "/Monetization/Invoice/CreateFromTemplate/" + templateId
            },
            GetInvoiceById(id) {
                return getApiAddress() + "/Monetization/Invoice/" + id
            },
            GetAllInvoicesSkipTake(skip, take) {
                return getApiAddress() + "/Monetization/Invoice/Skip/" + skip + "/Take/" + take
            },
            GetAllInvoicesByUserIdSkipTake(userId, skip, take) {
                return getApiAddress() + "/Monetization/Invoice/User/" + userId + "/Skip/" + skip + "/Take/" + take
            }
        }
    }
}